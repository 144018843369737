body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.hero-container {
  position: relative; /* To position child elements */
  width: 100%;
  margin-bottom: 2em; /* Space below the hero */
}

.hero-image {
  width: 100%;
  height: 300px; /* Adjust as needed */
  object-fit: cover; /* Cover the container */
}

.hero-buttons {
  position: absolute;
  bottom: 20px; /* Adjust position */
  left: 50%;
  transform: translateX(-50%); /* Center horizontally */
  display: flex;
  gap: 1rem;
}